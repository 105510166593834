<template>
  <v-row>
    <v-col cols="12">
      <div v-if="filters.order_ids && filters.order_ids.length > 0">
        <v-chip
          class="mr-1 mb-1"
          v-for="od in filters.scannedOrders"
          :key="`sc_${od.tracking_id}`"
        >
          {{ od.tracking_id }}
        </v-chip>
      </div>
      <v-simple-table class="pickup-table" v-else>
        <template v-slot:default>
          <tbody>
            <tr class="info white--text font-weight-bold">
              <td>{{ $t(`labels.${filters.config.display}`) }}</td>
              <td>{{ filters.config.count }}</td>
              <td><v-icon class="white--text">mdi-checkbox-marked</v-icon></td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
    <v-col cols="12">
      <v-text-field
        v-model.number="basket_code"
        :label="$t('labels.scan_basket_code')"
        class="c-input-small"
        autofocus
        dense
        outlined
        clearable
        hide-details
        :disabled="disabledInputBasket"
        @keyup.enter="scanBasket"
        append-icon="mdi-qrcode-scan"
        @click:append="showQRCodeScan('basket_code')"
      ></v-text-field>
    </v-col>
    <v-col cols="12">
      <v-card class="mx-auto" outlined>
        <v-list-item three-line>
          <v-list-item-content>
            <div>
              <v-chip
                v-for="b in basketReverse"
                :key="`basket_${b.id}`"
                class="mr-1 mb-1"
                color="primary"
              >
                {{ b.code }} ({{ b.index }})
              </v-chip>
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </v-col>
    <v-col cols="6">
      <v-btn
        color="warning"
        block
        @click="backStep"
        :disabled="isWaitingCommand"
        >{{ $t("labels.back") }}</v-btn
      >
    </v-col>
    <v-col cols="6">
      <v-btn
        color="success"
        block
        @click="createCommand"
        :disabled="scanned_baskets.length === 0 || isWaitingCommand"
      >
        {{ $t("labels.start") }}</v-btn
      >
    </v-col>
    <v-col
      cols="12"
      v-if="
        filters.config.basket &&
        filters.config.basket > 1 &&
        filters.config.process == 4
      "
    >
      <div class="error--text font-italic text-center">
        {{ $t("labels.pickup_max_basket", { number: filters.config.basket }) }}
      </div>
    </v-col>

    <v-dialog v-model="qrScanDialog" max-width="100vw">
      <QRCodeScanner
        v-if="qrScanDialog"
        :name="qrScanType"
        :auto-close="false"
        @close="hideQRCodeScan"
        @onScanned="onQRScanned"
      />
    </v-dialog>
  </v-row>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "PickupStep3",
  components: {
    QRCodeScanner: () => import("@/components/common/QRCodeScanner"),
  },
  props: {
    selected: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    filters: {},
    basket_code: null,
    scanned_baskets: [],
    isWaitingCommand: false,
    qrScanDialog: false,
    qrScanType: null,
  }),
  created() {
    this.filters = { ...this.selected };
  },
  computed: {
    basketReverse() {
      return [...this.scanned_baskets].reverse();
    },
    disabledInputBasket() {
      return (
        this.filters.config &&
        this.filters.config.basket &&
        this.scanned_baskets.length >= this.filters.config.basket &&
        this.filters.order_ids &&
        this.scanned_baskets.length >= this.filters.order_ids.length
      );
    },
  },
  mounted() {
    this.getMaxBasket();
  },
  methods: {
    showQRCodeScan(type) {
      this.qrScanDialog = true;
      this.qrScanType = type;
    },
    hideQRCodeScan() {
      this.qrScanDialog = false;
      this.qrScanType = null;
    },
    onQRScanned(filter) {
      this[filter.name] = filter.value;
      this.scanBasket();
    },
    async getMaxBasket() {
      if (this.filters.config.process != 4) {
        return false;
      }
      httpClient
        .post("/goods-issue-detail/v1/pickup-get-max-basket", this.filters)
        .then(({ data }) => {
          if (data === 0) {
            this.backStep();
          }
          const config = {
            ...this.filters.config,
            basket: data || this.filters.config.basket,
          };
          this.filters = { ...this.filters, config };
          if (config.basket > 1) {
            document.getElementById(`DungXePickup_player`).play();
          }
        });
    },
    async scanBasket() {
      if (!this.basket_code) {
        return false;
      }

      if (this.disabledInputBasket) {
        return false;
      }

      const checkBasket = this.scanned_baskets.find(
        (c) => c.code == this.basket_code
      );
      if (checkBasket) {
        document.getElementById("error_sound_player").play();
        this.$vToastify.error(
          this.$t("messages.scanned_basket_code", { code: this.basket_code })
        );
        this.basket_code = null;
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        const { data } = await httpClient.post(
          "/goods-issue-detail/v1/pickup-get-basket",
          {
            basket_code: this.basket_code,
            id_warehouse: this.filters.id_warehouse,
          }
        );
        this.scanned_baskets.push({
          code: data.code,
          id: data.id,
          index: this.scanned_baskets.length + 1,
        });
        this.isLoading = false;
        this.basket_code = null;
        document.getElementById("success_sound_player").play();
        this.$vToastify.success(
          this.$t("messages.basket_taken", { basket_code: data.code })
        );
      } catch (e) {
        this.basket_code = null;
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        document.getElementById("error_sound_player").play();
      }
    },
    backStep() {
      this.$emit("onUpdate", {
        ...this.filters,
        process_type: null,
        pickup_type: null,
        order_ids: [],
        scannedOrders: [],
        id_command: null,
      });
    },
    async createCommand() {
      if (this.isLoading || this.isWaitingCommand) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;
      try {
        await httpClient.post("/goods-issue-detail/v1/pickup-create-command", {
          scanned_baskets: this.scanned_baskets,
          filters: this.filters,
        });
        this.isLoading = false;
        document.getElementById("success_sound_player").play();
        // disable all and waiting socket
        this.isWaitingCommand = true;
        // this.$emit('onUpdate', {...this.filters, id_command: data})
      } catch (e) {
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        document.getElementById("error_sound_player").play();
      }
    },
  },
};
</script>

<style scoped></style>
